@font-face {
  font-family: Slight;
  src: url('../slight.ttf');
}

@media screen and (max-width: 460px) {
  main {
    height: fit-content !important;
    padding-bottom: 50px;
  }

  .toolbar {
    flex-direction: column !important;
  }
}

header {
  background-color: white;
  height: 125px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

header img {
  height: 150px;
  margin: 0 auto;
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 375px;
}

main.password {
  height: 50vh !important;
  position: relative;
  padding: 0 !important;
}

main.password form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 200px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

main.password form button {
  max-width: 200px;
}

main.upload h1::first-letter {
  font-family: Slight;
  font-size: 2em;
}

main.upload h1 {
  font-family: Slight;
  font-weight: normal;
  text-align: center;
}

footer {
  background-color: white;
  height: 200px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.15);
}

div.formContainer {
  max-width: 250px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

form input {
  width: 100%;
  padding: 10px;
  border: 1px black dashed;
}

.progress-container {
  max-width: 200px;
  background-color: lightblue;
  border-radius: 5px;
  overflow: hidden;
  margin: 0 0 20px 0;
  width: 100%;
}

.progress-bar {
  height: 25px;
  background-color: black;
  text-align: center;
  line-height: 25px;
  color: white;
  transition: width 0.4s ease;
}

input[type="password"] {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

input[type="password"]:focus {
  border-color: #007BFF;
  outline: none;
}

input[type="password"].error {
  border-color: #FF0000;
}

.text-input.success {
  border-color: #28a745;
}

p.small {
  font-size: 10px;
}

p.small {
  margin: 0;
}

/* PhotoGallery.css */
.photo-gallery {
  text-align: center;
}

.photo-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
  padding: 20px;
}

.photo img {
  width: 100%;
  height: auto;
  display: block;
}

.loading-message {
  margin-top: 20px;
}

header {
  position: relative;
}

.download-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: lightblue;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.download-button:hover {
  background-color: #0056b3;
}

.total-size {
  font-size: 10px;
  margin-top: 10px;
}

header img {
  cursor: pointer;
}

footer {
  padding: 10px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
}

.modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  padding: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
}

.modal-content h2 {
  font-family: Slight;
  font-size: 2em;
  font-weight: normal;
  margin-bottom: 0;
}

.modal-content p {
  font-size: 12px;
}

.image-style {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 10px;
  cursor: pointer;
  background:
    repeating-linear-gradient(45deg,
      transparent,
      transparent 10px,
      #ccc 10px,
      #ccc 20px);
}

.selected {
  border: 2px solid black;
}

.toolbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* Center items vertically */
  justify-content: center;
  /* Center items horizontally */
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  position: sticky;
  top: 0;
  z-index: 12;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.85);
}

/* Container for video and overlay */
.media-container {
  position: relative;
  display: inline-block;
}

/* Overlay container */
.overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
  color: white;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  z-index: 1; /* Make sure it's on top of the video */
  width: 100%;
}

/* Overlay text styling */
.overlay-text {
  font-size: 15px;
}

.error {
  text-align: center;
  color: red;
  opacity: 0.8;
  font-size: 12px;
  margin-top: 0;
}